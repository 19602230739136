import React from 'react'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/Content'
import { parseDocument, toHTML } from '../parsers'
import ContentGrid from '../components/ContentGrid'
import ArrowLeftIcon from '../images/arrow-left.inline.svg'
import ArrowRightIcon from '../images/arrow-right.inline.svg'

const linkStyle = (theme) => css`
	${theme.typography.basic};
    color: ${theme.colors.blue};
    text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px 0;

	#Artboard {
		fill: ${theme.colors.blue};
	}
`

const bottomNavigationContainer = (theme) => css`
	display: flex;
	border-top: 1px solid ${theme.colors.blue};
	justify-content: space-between;
`

const buttonLabel = theme => css`
	margin-top: 5px;
    color: ${theme.colors.blue};
`

const DocumentPage = ({ data, pageContext }) => {
	const { language, next, previous } = pageContext
	let { title, en_title, description, slug, enSlug, images, en_description, creator } = parseDocument({
		markdownRemark: data[language],
	})
	let parsedDescription = toHTML(description)
	let linkToDifferentLanguage = enSlug
	if (language === 'en') {
		linkToDifferentLanguage = slug
		title = en_title
		parsedDescription = toHTML(en_description)
	}
	return (
		<Layout menu="2020" language={language} linkToDifferentLanguage={linkToDifferentLanguage}>
			<SEO title={title} />
			<Content gallery={images} subtitle={creator && creator.join(', ')} title={title} language={language} html={parsedDescription} />
			<ContentGrid>
				<div css={bottomNavigationContainer}>
					{previous ? (
						<Link to={previous} css={linkStyle}>
							<ArrowLeftIcon />
							<span css={buttonLabel}>Poprzedni</span>
						</Link>
					) : <div/>}
					{next ? (
						<Link to={next} css={linkStyle}>
							<ArrowRightIcon />
							<span css={buttonLabel}>Kolejny</span>
						</Link>
					) : <div />}
				</div>
			</ContentGrid>
		</Layout>
	)
}

export default DocumentPage

export const query = graphql`
	query($path: String!) {
		pl: markdownRemark(fields: { slug: { eq: $path } }) {
			...DocumentData
		}
		en: markdownRemark(fields: { enSlug: { eq: $path } }) {
			...DocumentData
		}
	}
`
