import * as React from 'react'
import { css } from '@emotion/core'
import Img from 'gatsby-image'

import ArrowLeftIcon from '../images/arrow-left.inline.svg'
import ArrowRightIcon from '../images/arrow-right.inline.svg'
import * as commonStyles from '../commonStyles'
import ExternalLink from './ExternalLink'

const subheaderStyle = (theme) => css`
	${theme.typography.header};
	margin-bottom: 0;
	grid-column: 2 / 3;

	${theme.breakpoints.tablet} {
		grid-column: 1 / 4;
	}
`

const headerStyle = italic => (theme) => css`
	${italic && theme.typography.italic};
	${italic && `margin-top: 0px`};
	${theme.typography.header};
	margin-bottom: 0;
	grid-column: 2 / 3;

	${theme.breakpoints.tablet} {
		grid-column: 1 / 4;
	}
`

const authorStyle = (theme) => css`
	${theme.typography.italic};
	${theme.typography.bold};
	grid-column: 2 / 3;

	${theme.breakpoints.tablet} {
		grid-column: 1 / 4;
	}
`

const contentStyle = (theme) => css`
	grid-column: 2 / 3;

	${theme.breakpoints.tablet} {
		grid-column: 1 / 4;
	}

	img {
		max-width: 100%;
	}

	blockquote {
		${theme.typography.big}
		margin-left: 125px;
		margin-right: 0;

		${theme.breakpoints.mobile} {
			margin-left: 30px;
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		${theme.typography.imageTitle};
		text-align: center;
		padding: 5px;
		max-width: 800px;
		margin: 0 auto;
	}

	div > .gatsby-resp-image-wrapper,
	div > p > .gatsby-resp-image-wrapper,
	div > .gatsby-resp-image-figure {
		margin-left: calc(100% / 10 * -1) !important;

		${theme.breakpoints.tablet} {
			margin-left: 0 !important;
		}
	}
`

const articleGridStyle = (theme) => css`
	${theme.grid.default};
`

const sidebarStyle = (theme) => css`
	${theme.typography.sidebar};

	h2,
	h3,
	h4,
	h5 {
		margin: 15px 0;
	}

	${theme.breakpoints.tablet} {
		grid-column: 1 / 4;
	}
`

const buttonStyle = (disabled) => (theme) => css`
	${commonStyles.resetButton};
	cursor: ${disabled ? 'auto' : 'pointer'};
	padding: 0;

	svg {
		height: 30px;
	}

	#Artboard {
		fill: ${disabled ? '#fff' : theme.colors.red};
		stroke: ${theme.colors.red};
		stroke-width: 1px;
	}
`

const galleryNavigation = hasImages => css`
	display: grid;
	grid-template-columns: ${hasImages ? '30px 1fr 30px' : '1fr'};
	margin-top: 10px;
`

const counterStyle = (theme) => css`
	position: absolute;
	z-index: 2;
	color: ${theme.colors.red};
	padding: 5px;
	right: 60px;
`

const galleryContainer = (theme) => css`
	position: relative;
	margin-top: 40px;
	margin-left: calc(100% / 10 * -1) !important;

	${theme.breakpoints.tablet} {
		margin-left: 0 !important;
	}
`

const imageTitle = (theme) => css`
	text-align: center;
	${theme.typography.basic};
	color: ${theme.colors.ecru};
	display: flex;
	align-items: center;
	justify-content: center;
`

const previewImageStyle = css`
	width: 100%;
`

const Content = ({ gallery, title, author, html, htmlPreview, column, preview, subtitle }) => {
	const [currentImageIndex, setCurrentImageIndex] = React.useState(0)
	const currentImage = React.useMemo(() => {
		return gallery && gallery[currentImageIndex]
	}, [currentImageIndex, gallery])
	return (
		<article css={articleGridStyle}>
			{subtitle && <div css={subheaderStyle}>{subtitle}</div>}
			<h1 css={headerStyle(!!subtitle)}>{title}</h1>
			{author && <p css={authorStyle}>[Autor: {author}]</p>}
			<div css={contentStyle}>
				{gallery && (
					<div css={galleryContainer}>
						<div css={counterStyle}>
							{currentImageIndex + 1}({gallery.length})
						</div>
						{!preview && <ExternalLink link={currentImage.image_source.childImageSharp.original.src}>
							<Img fluid={currentImage.image_source.childImageSharp.fluid} alt={currentImage.image_alt} />
						</ExternalLink> }
						{preview && <img css={previewImageStyle} src={currentImage.image_source} alt={currentImage.image_alt} />}
						<div css={galleryNavigation(gallery.length > 1)}>
							{gallery.length > 1 && <button
								aria-label="Poprzednie zdjęcie"
								css={buttonStyle(currentImageIndex === 0)}
								disabled={currentImageIndex === 0}
								onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
							>
								<ArrowLeftIcon />
							</button> }
							<div css={imageTitle}>{currentImage.image_title}</div>
							{gallery.length > 1 && <button
								aria-label="Następne zdjęcie"
								css={buttonStyle(currentImageIndex === gallery.length - 1)}
								disabled={currentImageIndex === gallery.length - 1}
								onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
							>
								<ArrowRightIcon />
							</button> }
						</div>
					</div>
				)}
				{html && (
					<div
						dangerouslySetInnerHTML={{
							__html: html,
						}}
					/>
				)}
				{htmlPreview}
			</div>
			{column && (
				<section
					css={sidebarStyle}
					dangerouslySetInnerHTML={{
						__html: column,
					}}
				/>
			)}
		</article>
	)
}

export default Content
